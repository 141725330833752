import "./expenses.styles.scss"

import Expenses from "../../../pages/signed-out/expenses/expenses.component"

const ExpensesRoute = () => {
  return (
    <Expenses></Expenses>
  )
}

export default ExpensesRoute