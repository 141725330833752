import styled from "styled-components";

export const ExpensesSummaryDashboardContainer = styled.div`
  
`

export const ExpensesSummaryInfoGraphContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`