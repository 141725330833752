import styled from "styled-components";

export const InvestmentsContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`

export const InvestmentSummaryContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`