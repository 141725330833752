import styled from "styled-components";

export const ExpensesContainer = styled.div`

`

export const ExpensesFilterContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`