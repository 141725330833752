export const INSURANCE_ACTION_TYPES = {
  SET_INSURANCES: "insurance/SET_INSURANCES",
  SET_INSURANCE_PAYMENTS: "insurance/SET_INSURANCE_PAYMENTS",
  SET_FILTER_CONDITIONS: "insurance/SET_FILTER_CONDITIONS",

  SET_SELECTED_INSURANCE_PAYMENTS_DATE: "insurance/SET_SELECTED_INSURANCE_PAYMENTS_DATE",
  SET_SCHEDULED_INSURANCE_PAYMENTS_VIEW: "insurance/SET_SCHEDULED_INSURANCE_PAYMENTS_VIEW",

  SET_INSURANCES_VIEW: "insurance/SET_INSURANCES_VIEW",
  SET_INSURANCE_PAYMENTS_VIEW: "insurance/SET_INSURANCE_PAYMENTS_VIEW",
  SET_INSURANCES_SUMMARY: "insurance/SET_INSURANCES_SUMMARY"
}