import "./dashboard.styles.scss";

import Summary from "../../../pages/signed-in/dashboard/summary.component";

const DashboardRouteSignedIn = () => {
  return (
    <div>
      <Summary></Summary>
    </div>
  );
};

export default DashboardRouteSignedIn;