import styled from "styled-components";

export const DashboardContainer = styled.div`

`

export const FinanceItemsSummary = styled.div`

`

export const FinanceItemsSummaryInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`