export const EXPENSES_ACTION_TYPES = {
  SET_EXPENSES: "expenses/SET_EXPENSES",
  SET_EXPENSES_TAG_LIMIT: "expenses/SET_EXPENSES_TAG_LIMIT",
  SET_FILTER_CONDITIONS: "expenses/SET_FILTER_CONDITIONS",

  SET_SELECTED_EXPENSES_DATE: "expenses/SET_SELECTED_EXPENSES_DATE",
  SET_SCHEDULED_EXPENSES_VIEW: "expenses/SET_SCHEDULED_EXPENSES_VIEW",

  SET_EXPENSES_VIEW: "expenses/SET_EXPENSES_VIEW",
  SET_EXPENSES_SUMMARY: "expenses/SET_EXPENSES_SUMMARY"
}