// expenses constants

export const DEFAULT_EXPENSES = []

export const DEFAULT_EXPENSES_SUMMARY = {}

export const BANKING_EXPENSE_CATEGORIES = {
  deposit: "bankingdeposit",
  withdrawal: "bankingwithdrawal",
  transfer: "bankingtransfer"
}