import styled from "styled-components";

export const InsuranceContainer = styled.div`

`

export const InsuranceFilterContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`