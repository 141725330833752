import styled from "styled-components";

export const SavingsContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`

export const SavingsSummaryContainer = styled.div`
  display: block;
  justify-content: center;
  align-items: center;
`