import "./dashboard.styles.scss";

import Summary from "../../../pages/signed-out/dashboard/summary.component";

const DashboardRoute = () => {
  return (
    <div>
      <Summary></Summary>
    </div>
  );
};

export default DashboardRoute;