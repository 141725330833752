import "./expenses.styles.scss"

import Expenses from "../../../pages/signed-in/expenses/expenses.component"

const ExpensesRouteSignedIn = () => {
  return (
    <Expenses></Expenses>
  )
}

export default ExpensesRouteSignedIn